<template>
  <div class="NewsDetails3">
    <van-popup
      v-model="this.$store.state.show"
      position="right"
      :style="{ height: '100%', width: '223px' }"
      class="genduomenu"
    >
      <el-input
        suffix-icon="el-icon-search"
        class="PeSearch"
        v-model="searchText"
      >
      </el-input>
      <ul class="peMenu">
        <li @click="toHome">
          <span>首页</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toBusiness">
          <span>经营范围</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toNews">
          <span>新闻资讯</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toAdvantage">
          <span>核心优势</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toGroup_Introduction">
          <span>集团介绍</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toContact_us">
          <span>联系我们</span><i class="el-icon-arrow-right"></i>
        </li>
      </ul>
    </van-popup>
    <BusinessHeader></BusinessHeader>
    <div class="layer1" @click.stop="popHide">
      <div class="titles">
        <h2>
          {{ $t('NewsDetails3.NewsAndInformation') }}<span>/</span>
          <span>{{ $t('NewsDetails3.NewsAndInformationE') }}</span>
        </h2>
      </div>
      <el-divider></el-divider>
      <div class="content">
        <h2 class="title">{{ $t('NewsDetails3.Title') }}</h2>
        <h4>2021-04-18 14:69 <span>焕昱光电</span></h4>
        <div class="first">
          {{ $t('NewsDetails3.Content1') }}
        </div>
        <img src="../../assets/News/243.png" alt="" />
        <div class="article">
          <p>
            {{ $t('NewsDetails3.Source') }}
          </p>
          <p>
            {{ $t('NewsDetails3.Content2') }}
          </p>
          <p>
            {{ $t('NewsDetails3.Content3') }}
          </p>
          <p>
            {{ $t('NewsDetails3.Content4') }}
          </p>
          <p>
            {{ $t('NewsDetails3.Content5') }}
          </p>
          <p>
            {{ $t('NewsDetails3.Content6') }}
          </p>
          <p>
            {{ $t('NewsDetails3.Content7') }}
          </p>
          <p>
            {{ $t('NewsDetails3.Content8') }}
          </p>
          <p>
            {{ $t('NewsDetails3.Content9') }}
          </p>
        </div>
        <h3>{{ $t('NewsDetails3.Source') }}</h3>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import BusinessHeader from '../../components/BusinessHeader.vue'
import footers from '../../components/footer.vue'

export default {
  components: {
    BusinessHeader,
    footers
  },
  data () {
    return {
      searchText: ''
    }
  },
  created () {
    this.$store.commit('setShow', false)
  },
  methods: {
    toNewEnergy () {
      this.$router.push('/NewEnergy')
    },
    toCar () {
      this.$router.push('/Car')
    },
    toIndustrial () {
      this.$router.push('/Industrial')
    },
    toCommunication () {
      this.$router.push('/Communication')
    },
    toInstrument () {
      this.$router.push('/Instrument')
    },
    toMotor () {
      this.$router.push('/Motor')
    },
    toRobot () {
      this.$router.push('/Robot')
    },
    toElectronic () {
      this.$router.push('/Electronic')
    },
    toHome () {
      this.$router.push('/Home')
    },
    toBusiness () {
      this.$router.push('/Business')
    },
    toNews () {
      this.$router.push('/News')
    },
    toAdvantage () {
      this.$router.push('/Advantage')
    },
    toGroup_Introduction () {
      this.$router.push('/Group_Introduction')
    },
    toContact_us () {
      this.$router.push('/Contact_us')
    },
    popHide () {
      this.$store.commit('setShow', false)
    }
  }
}
</script>

<style lang="less" scoped>
.NewsDetails3 {
  width: 100%;
  #header {
    width: 100%;
    height: 960px;
    background: url('../../assets/Home/458.png') no-repeat;
    background-size: cover;
    position: relative;
    .top {
      width: 1400px;
      height: 80px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      .logo {
        width: 95px;
        height: 27px;
        margin-right: 30px;
      }
      .left {
        width: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          color: #fff;
          list-style: none;
          display: block;
          cursor: pointer;
          font-size: 16px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          .homeIcon {
            margin-right: 10px;
          }
        }
        .first {
          border-left: 1px solid #fff;
          padding-left: 21px;
          display: flex;
          align-items: center;
        }
      }
      .right {
        margin-left: 145px;
        width: 280px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/ .el-input {
          width: 206px;
          height: 36px;
          .el-input__inner {
            background-color: rgba(255, 255, 255, 0.3);
            -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
            border: 0;
            border-radius: 25px;
            color: #fff;
          }
        }
      }
    }
  }
  .layer1 {
    width: 1554px;
    margin: 0 auto;
    .titles {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 80px;
      line-height: 80px;
      padding-bottom: 60px;
      margin-top: 90px;
      h2 {
        display: block;
        width: 760px;
        font-size: 40px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
        display: flex;
      }
      span {
        font-size: 16px;
        font-family: Didot, Didot-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        &:nth-child(1) {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
    .content {
      width: 100%;
      margin: 0 auto;
      .title {
        font-size: 40px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
        margin-bottom: 10px;
      }
      h4 {
        font-size: 16px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        margin-bottom: 90px;
        span {
          margin-left: 10px;
          color: #4898ff;
        }
      }
      .first {
        font-size: 16px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 40px;
        margin-bottom: 90px;
      }
      .article {
        margin-top: 90px;
        p {
          font-size: 16px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
          margin-bottom: 30px;
        }
      }
      h3 {
        text-align: right;
        font-size: 16px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .footer {
    background: url('../../assets/Home/bottom.png');
    background-size: cover;
    height: 470px;
    margin-top: 90px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .top {
      width: 1400px;
      height: 40px;
      margin: 0 auto;
      padding-top: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        height: 41px;
      }
      .right {
        display: flex;
        justify-content: space-between;
        li {
          margin-left: 100px;
          display: flex;
          align-items: center;
        }
      }
    }
    .bottom {
      width: 1400px;
      height: 32px;
      margin: 0 auto;
      margin-top: 92px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .first {
        display: flex;
        align-items: center;
        .left {
          margin-right: 10px;
        }
      }
      .last {
        display: flex;
        align-items: center;
        * {
          margin-left: 30px;
        }
        span {
          font-size: 20px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .NewsDetails3 {
    width: 100%;
    .layer1 {
      width: 100%;
      margin: 0 auto;
      padding-left: 15px;
      padding-right: 15px;
      .titles {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-bottom: 0px;
        margin-top: 10px;
        h2 {
          display: block;
          width: 100%;
          font-size: 15px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
          display: flex;
        }
        span {
          font-size: 15px;
          font-family: Didot, Didot-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          &:nth-child(1) {
            margin-left: 20px;
            margin-right: 20px;
          }
        }
      }
      .content {
        width: 100%;
        margin: 0 auto;
        .title {
          font-size: 15px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
          margin-bottom: 10px;
        }
        img {
          width: 100%;
          height: 220px;
        }
        h4 {
          font-size: 16px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          margin-bottom: 10px;
          span {
            margin-left: 10px;
            color: #4898ff;
          }
        }
        .first {
          font-size: 12px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
          line-height: 20px;
          margin-bottom: 10px;
        }
        .article {
          margin-top: 20px;
          p {
            font-size: 12px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
            margin-bottom: 10px;
          }
        }
        h3 {
          text-align: right;
          font-size: 12px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          margin-bottom: 0px !important;
          color: #333333;
        }
      }
    }
  }
}
</style>
